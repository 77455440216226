export default {
  hero: {
    title: '',
    subtitle: 'Work with me to achieve your fitness and nutrition goals',
    backgroundImage: 'https://res.cloudinary.com/dmfebxjzk/image/upload/v1565596292/tadgh%20website/box-jump.webp',
    ctaText: 'JOIN ME'
  },
  programs: {
    image: 'https://res.cloudinary.com/dmfebxjzk/image/upload/v1565596113/tadgh%20website/excersise-silhouette.png',
    header: 'Our Programs',
    list: [{
      icon: 'laptop',
      title: 'Online Consultation',
      details: 'Programming for those who cannot make it in person. Focused on variety and results.'
    }, {
      icon: 'utensils',
      title: 'Nutrition Plans',
      details: 'Exercise is only one part of the puzzle. I will create a nutrition plan that is suited to your energy output, fitness goals, and body type.'
    }, {
      icon: 'futbol',
      title: 'Sport Specific',
      details: 'Targeted trained for precise muscle groups for maximum results for your sport.'
    }]
  },
  startTodayCta: {
    title: 'Time to start your fitness journey',
    subtitle: 'Book a free fitness and nutrition consultation',
    ctaText: 'Start Today',
    backgroundImage: 'https://res.cloudinary.com/dmfebxjzk/image/upload/v1564129023/tadgh%20website/running.jpg'
  },
  bio: {
    image: 'https://res.cloudinary.com/dmfebxjzk/image/upload/v1565595510/tadgh%20website/tadgh2.jpg'
  },
  pricing: {
    packages: [{
      title: 'Part Time Online',
      price: 60,
      time: 'month',
      details: ['3 days of programming a week', 'Feedback through online platform', 'Nutritional Advice', 'Free 5k running plan']
    }, {
      title: 'Full Time Online',
      price: 100,
      time: 'month',
      details: ['6 days of programming a week', 'Feedback through online platform', 'Nutritional Advice', 'Free 5k running plan']
    }]
  },
  team: {
    heading: 'Team Training Available',
    body: 'I work with teams including Mallow Footballers to build strength and conditioning. Contact me directly for quotes and a free consultation.',
    ctaText: 'Find out more'
  },
  contact: {
    imageUrl: 'https://res.cloudinary.com/dmfebxjzk/image/upload/v1564916192/tadgh%20website/gym.jpg'
  }
};