import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.replace";

/* eslint-disable no-undef */

/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components';
export var hexToRgba = function hexToRgba(hex, alpha) {
  var hexToRgb = function hexToRgb() {
    var regex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    var newHex = hex.replace(regex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  };

  var _hexToRgb = hexToRgb(),
      r = _hexToRgb.r,
      g = _hexToRgb.g,
      b = _hexToRgb.b;

  return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
};
var breakpoints = {
  hd: 1920,
  sd: 1366,
  tablet: 1024,
  tabletPortrait: 800,
  phone: 500
};
export var breakpointAbove = Object.keys(breakpoints).reduce(function (acc, label) {
  acc[label] = function () {
    return css(["@media (min-width:", "em){", "}"], breakpoints[label] / 16, css.apply(void 0, arguments));
  };

  return acc;
}, {});
export var breakpoint = Object.keys(breakpoints).reduce(function (acc, label) {
  acc[label] = function () {
    return css(["@media (max-width:", "em){", "}"], breakpoints[label] / 16, css.apply(void 0, arguments));
  };

  return acc;
}, {});
export var getWidth = function getWidth() {
  return Math.min(document.body.scrollWidth, document.documentElement.scrollWidth, document.body.offsetWidth, document.documentElement.offsetWidth, document.documentElement.clientWidth);
};